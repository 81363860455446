.error-container {
  width: 100%;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.error-text10 {
  display: inline-block;
  font-style: normal;
  font-weight: 700;
  text-decoration: none;
}
.error-text11 {
  display: inline-block;
  font-style: normal;
  text-align: left;
  font-weight: 700;
  text-decoration: none;
}
.error-text12 {
  display: inline-block;
  font-style: normal;
  font-weight: 700;
  text-decoration: none;
}
.error-text13 {
  display: inline-block;
  font-style: normal;
  font-weight: 700;
  text-decoration: none;
}
.error-text14 {
  display: inline-block;
  text-decoration: none;
}
.error-text15 {
  display: inline-block;
  font-style: normal;
  font-weight: 700;
  text-decoration: none;
}
.error-text16 {
  display: inline-block;
}
.error-text17 {
  display: inline-block;
}
.error-text18 {
  display: inline-block;
  text-align: center;
}
.error-text19 {
  display: inline-block;
}
.error-text20 {
  display: inline-block;
}
.error-text21 {
  display: inline-block;
}
.error-text22 {
  display: inline-block;
}
.error-text23 {
  display: inline-block;
}
.error-text24 {
  display: inline-block;
}
.error-text25 {
  display: inline-block;
}
.error-text26 {
  display: inline-block;
}
.error-text27 {
  display: inline-block;
}
@media(max-width: 991px) {
  .error-text14 {
    text-decoration: none;
  }
}
@media(max-width: 767px) {
  .error-text18 {
    text-align: center;
  }
}
