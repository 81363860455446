.team-max-width {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.team-section-title {
  gap: var(--dl-space-space-unit);
  width: 100%;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
}
.team-content10 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
.team-slider1 {
  width: 100%;
  height: auto;
  display: inline-block;
  position: relative;
}
.team-slider-slide1 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  padding-bottom: var(--dl-space-space-threeunits);
}
.team-card10 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.team-placeholder-image10 {
  width: 100%;
}
.team-content11 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: center;
  flex-direction: column;
}
.team-title10 {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.team-social-icons10 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
}
.team-card11 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.team-placeholder-image11 {
  width: 100%;
}
.team-content12 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: center;
  flex-direction: column;
}
.team-title11 {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.team-social-icons11 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
}
.team-slider-slide2 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  padding-bottom: var(--dl-space-space-threeunits);
}
.team-card12 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.team-placeholder-image12 {
  width: 100%;
}
.team-content13 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: center;
  flex-direction: column;
}
.team-title12 {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.team-social-icons12 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
}
.team-card13 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.team-placeholder-image13 {
  width: 100%;
}
.team-content14 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: center;
  flex-direction: column;
}
.team-title13 {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.team-social-icons13 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
}
.team-slider-slide3 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  padding-bottom: var(--dl-space-space-threeunits);
}
.team-card14 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.team-placeholder-image14 {
  width: 100%;
}
.team-content15 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: center;
  flex-direction: column;
}
.team-title14 {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.team-social-icons14 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
}
.team-card15 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.team-placeholder-image15 {
  width: 100%;
}
.team-content16 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: center;
  flex-direction: column;
}
.team-title15 {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.team-social-icons15 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
}
.team-slider-pagination1 {
  display: block;
}
.team-slider2 {
  width: 100%;
  height: auto;
  display: none;
  position: relative;
}
.team-slider-slide4 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  padding-bottom: var(--dl-space-space-threeunits);
}
.team-card16 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.team-placeholder-image16 {
  width: 80%;
}
.team-content17 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: center;
  flex-direction: column;
}
.team-title16 {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.team-social-icons16 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
}
.team-slider-slide5 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  padding-bottom: var(--dl-space-space-threeunits);
}
.team-card17 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.team-placeholder-image17 {
  width: 80%;
}
.team-content18 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: center;
  flex-direction: column;
}
.team-title17 {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.team-social-icons17 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
}
.team-slider-slide6 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  padding-bottom: var(--dl-space-space-threeunits);
}
.team-card18 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.team-placeholder-image18 {
  width: 80%;
}
.team-content19 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: center;
  flex-direction: column;
}
.team-title18 {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.team-social-icons18 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
}
.team-slider-slide7 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  padding-bottom: var(--dl-space-space-threeunits);
}
.team-card19 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.team-placeholder-image19 {
  width: 80%;
}
.team-content20 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: center;
  flex-direction: column;
}
.team-title19 {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.team-social-icons19 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
}
.team-slider-slide8 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  padding-bottom: var(--dl-space-space-threeunits);
}
.team-card20 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.team-placeholder-image20 {
  width: 80%;
}
.team-content21 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: center;
  flex-direction: column;
}
.team-title20 {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.team-social-icons20 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
}
.team-slider-slide9 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  padding-bottom: var(--dl-space-space-threeunits);
}
.team-card21 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.team-placeholder-image21 {
  width: 80%;
}
.team-content22 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: center;
  flex-direction: column;
}
.team-title21 {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.team-social-icons21 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
}
.team-slider-pagination2 {
  display: block;
}
.team-content23 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  max-width: 800px;
  align-items: center;
  flex-direction: column;
}
.team-content24 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
}
.team-button {
  width: 100%;
  max-width: 300px;
}
.team-text62 {
  display: inline-block;
}
.team-text63 {
  display: inline-block;
  overflow: auto;
  max-height: var(--dl-size-size-medium);
  text-align: center;
}
.team-text64 {
  display: inline-block;
  text-align: center;
}
.team-text65 {
  display: inline-block;
  text-align: center;
}
.team-text66 {
  display: inline-block;
  text-align: center;
}
.team-text67 {
  display: inline-block;
  font-stretch: normal;
}
.team-text68 {
  display: inline-block;
  text-align: center;
}
.team-text69 {
  display: inline-block;
  text-align: center;
}
.team-text70 {
  display: inline-block;
  overflow: auto;
  max-height: var(--dl-size-size-medium);
  text-align: center;
}
.team-text71 {
  display: inline-block;
}
.team-text72 {
  display: inline-block;
  font-style: normal;
  font-weight: 600;
}
.team-text73 {
  display: inline-block;
  text-align: center;
}
.team-text74 {
  display: inline-block;
  font-style: normal;
  font-weight: 600;
}
.team-text75 {
  display: inline-block;
  overflow: auto;
  max-height: var(--dl-size-size-medium);
  text-align: center;
}
.team-text76 {
  display: inline-block;
  font-style: normal;
  font-weight: 600;
}
.team-text77 {
  display: inline-block;
  overflow: auto;
  max-height: var(--dl-size-size-medium);
  text-align: center;
}
.team-text78 {
  display: inline-block;
  font-style: normal;
  font-weight: 600;
}
.team-text79 {
  display: inline-block;
  overflow: hidden;
  max-height: var(--dl-size-size-medium);
  text-align: center;
}
.team-text80 {
  display: inline-block;
}
.team-text81 {
  display: inline-block;
  font-style: normal;
  font-weight: 600;
}
.team-text82 {
  display: inline-block;
  overflow: auto;
  max-height: var(--dl-size-size-medium);
  text-align: center;
}
.team-text83 {
  display: inline-block;
  font-style: normal;
  font-weight: 600;
}
@media(max-width: 767px) {
  .team-team8 {
    padding: var(--dl-space-space-threeunits);
  }
  .team-max-width {
    padding: var(--dl-space-space-threeunits);
  }
  .team-slider1 {
    display: none;
  }
  .team-slider2 {
    display: block;
  }
}
@media(max-width: 479px) {
  .team-team8 {
    padding: var(--dl-space-space-oneandhalfunits);
  }
  .team-max-width {
    padding: var(--dl-space-space-oneandhalfunits);
  }
  .team-placeholder-image16 {
    width: 100%;
  }
  .team-placeholder-image17 {
    width: 100%;
  }
  .team-placeholder-image18 {
    width: 100%;
  }
  .team-placeholder-image19 {
    width: 100%;
  }
  .team-placeholder-image20 {
    width: 100%;
  }
  .team-placeholder-image21 {
    width: 100%;
  }
  .team-button {
    width: 100%;
  }
  .team-text80 {
    text-align: center;
  }
}
