.title-max-width {
  display: flex;
  align-self: center;
  align-items: center;
  justify-content: center;
}
.title-content {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  max-width: 1000px;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
.title-actions {
  gap: var(--dl-space-space-unit);
  border: 2px dashed rgba(120, 120, 120, 0.4);
  display: flex;
  align-items: flex-start;
}
.title-text2 {
  display: inline-block;
  text-align: center;
}

@media(max-width: 479px) {
  .title-actions {
    width: 100%;
    flex-direction: column;
  }
}
