.objectives-layout349 {
  gap: var(--dl-space-space-twounits);
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: flex-start;
  flex-direction: row;
  justify-content: center;
}
.objectives-max-width {
  gap: var(--dl-space-space-threeunits);
  display: flex;
  align-items: center;
  flex-direction: column;
}
.objectives-container1 {
  gap: var(--dl-space-space-unit);
  display: flex;
  max-width: 600px;
  align-items: center;
  flex-direction: column;
}
.objectives-container3 {
  flex: 1;
  height: auto;
  display: flex;
  align-self: flex-start;
  box-shadow: 2px 2px 4px 0px #d4d4d4;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-theme-accent1);
}
.objectives-container4 {
  flex: 1;
  height: auto;
  display: flex;
  align-self: flex-start;
  box-shadow: 2px 2px 4px 0px #d4d4d4;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-theme-accent2);
}
.objectives-container5 {
  flex: 1;
  height: auto;
  display: flex;
  align-self: flex-start;
  box-shadow: 2px 2px 4px 0px #d4d4d4;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-theme-accent1);
}
.objectives-text18 {
  display: inline-block;
}
.objectives-text19 {
  display: inline-block;
}
.objectives-text20 {
  display: inline-block;
}
.objectives-text21 {
  display: inline-block;
}
.objectives-text22 {
  display: inline-block;
}
.objectives-text23 {
  display: inline-block;
}
.objectives-text24 {
  display: inline-block;
}
.objectives-text25 {
  display: inline-block;
}
@media(max-width: 991px) {
  .objectives-max-width {
    flex-direction: column;
  }
  .objectives-container1 {
    margin-bottom: var(--dl-space-space-threeunits);
  }
}
@media(max-width: 767px) {
  .objectives-container1 {
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .objectives-container3 {
    width: 100%;
  }
  .objectives-container4 {
    width: 100%;
  }
  .objectives-container5 {
    width: 100%;
  }
}
@media(max-width: 479px) {
  .objectives-max-width {
    gap: var(--dl-space-space-oneandhalfunits);
  }
}
