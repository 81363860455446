.feature-layout301 {
  gap: var(--dl-space-space-fiveunits);
  width: 100%;
  height: auto;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
  justify-content: center;
}
.feature-max-width {
  gap: var(--dl-space-space-threeunits);
  display: flex;
  align-items: center;
  flex-direction: column;
}
.feature-row {
  width: 100%;
}
.feature-feature1 {
  gap: var(--dl-space-space-twounits);
  flex: 1;
  width: auto;
  display: flex;
  overflow: hidden;
  max-width: 600px;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
.feature-content1 {
  align-self: stretch;
  align-items: center;
}
.feature-section-title1 {
  align-self: stretch;
  align-items: center;
}
.feature-feature2 {
  gap: var(--dl-space-space-twounits);
  flex: 1;
  width: auto;
  display: flex;
  overflow: hidden;
  max-width: 600px;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
.feature-content2 {
  align-self: stretch;
  align-items: center;
}
.feature-section-title2 {
  align-self: stretch;
  align-items: center;
}
.feature-feature3 {
  gap: var(--dl-space-space-twounits);
  flex: 1;
  width: auto;
  display: flex;
  overflow: hidden;
  max-width: 600px;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
.feature-content3 {
  align-self: stretch;
  align-items: center;
}
.feature-section-title3 {
  align-self: stretch;
  align-items: center;
}
.feature-text2 {
  display: inline-block;
  text-align: center;
}
.feature-text3 {
  display: inline-block;
  text-align: center;
}
.feature-text4 {
  display: inline-block;
  text-align: center;
}
.feature-text5 {
  display: inline-block;
  text-align: center;
}
.feature-text6 {
  display: inline-block;
  text-align: center;
}
.feature-text7 {
  display: inline-block;
  text-align: center;
}
.feature-text8 {
  display: inline-block;
  text-align: center;
}

@media(max-width: 991px) {
  .feature-row {
    width: auto;
    align-items: center;
    flex-direction: column;
  }
  .feature-feature1 {
    width: 100%;
  }
  .feature-feature2 {
    width: 100%;
  }
  .feature-feature3 {
    width: 100%;
  }
}
@media(max-width: 767px) {
  .feature-text2 {
    text-align: center;
  }
}
@media(max-width: 479px) {
  .feature-max-width {
    gap: var(--dl-space-space-oneandhalfunits);
  }
}
