.contact-contact20 {
  gap: var(--dl-space-space-threeunits);
  display: flex;
  overflow: hidden;
  position: relative;
  flex-direction: column;
}
.contact-max-width {
  gap: var(--dl-space-space-threeunits);
  display: flex;
  align-items: flex-start;
  flex-shrink: 0;
}
.contact-content1 {
  gap: var(--dl-space-space-oneandhalfunits);
  flex: 1;
  width: 100%;
  display: flex;
  flex-grow: 1;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
}
.contact-contact-info1 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
.contact-content2 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
.contact-text10 {
  align-self: stretch;
  text-align: center;
}
.contact-content3 {
  gap: var(--dl-space-space-oneandhalfunits);
  flex: 1;
  width: 100%;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
}
.contact-contact-info2 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
.contact-content4 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
.contact-text12 {
  align-self: stretch;
  text-align: center;
}
.contact-content5 {
  gap: var(--dl-space-space-oneandhalfunits);
  flex: 1;
  width: 100%;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
}
.contact-contact-info3 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
.contact-content6 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
.contact-text14 {
  align-self: stretch;
  text-align: center;
}
.contact-text16 {
  display: inline-block;
  text-align: center;
}
.contact-text17 {
  display: inline-block;
  text-align: center;
}
.contact-text18 {
  display: inline-block;
  text-align: center;
}
.contact-text19 {
  display: inline-block;
  text-align: center;
}
.contact-text20 {
  display: inline-block;
  text-align: center;
}
.contact-text21 {
  display: inline-block;
  text-align: center;
}
.contactroot-class-name {
  align-self: center;
}
@media(max-width: 767px) {
  .contact-max-width {
    flex-direction: column;
  }
}
