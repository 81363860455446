.header-container {
  width: 100%;
  display: flex;
  position: relative;
  justify-content: center;
  background-color: var(--dl-color-theme-neutral-light);
}
.header-navbar-interactive {
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  padding-bottom: var(--dl-space-space-twounits);
  justify-content: space-between;
}
.header-navlink1 {
  display: contents;
}
.header-image1 {
  height: var(--dl-size-size-medium);
  text-decoration: none;
}
.header-desktop-menu {
  flex: 1;
  display: flex;
  position: relative;
  justify-content: space-between;
}
.header-links1 {
  gap: var(--dl-space-space-twounits);
  flex: 1;
  width: auto;
  height: auto;
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.header-link6 {
  display: contents;
}
.header-link7 {
  display: contents;
}
.header-link8 {
  display: contents;
}
.header-link9 {
  display: contents;
}
.header-link10 {
  display: contents;
}
.header-buttons1 {
  gap: var(--dl-space-space-twounits);
  display: flex;
  align-items: center;
  flex-direction: row;
}
.header-action11 {
  display: flex;
  flex-direction: row;
  text-decoration: none;
}
.header-burger-menu {
  display: none;
}
.header-icon10 {
  width: var(--dl-size-size-small);
  height: var(--dl-size-size-xsmall);
}
.header-mobile-menu {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  display: none;
  padding: var(--dl-space-space-twounits);
  z-index: 100;
  position: absolute;
  flex-direction: column;
  justify-content: space-between;
  background-color: var(--dl-color-theme-neutral-light);
}
.header-nav {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.header-top {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
}
.header-navlink2 {
  display: contents;
}
.header-logo {
  height: 3rem;
  text-decoration: none;
}
.header-close-menu {
  display: flex;
  align-items: center;
  justify-content: center;
}
.header-icon12 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.header-links2 {
  gap: var(--dl-space-space-unit);
  flex: 0 0 auto;
  display: flex;
  align-self: flex-start;
  align-items: flex-start;
  flex-direction: column;
}
.header-navlink3 {
  display: contents;
}
.header-link12 {
  text-decoration: none;
}
.header-navlink4 {
  display: contents;
}
.header-link22 {
  text-decoration: none;
}
.header-link13 {
  display: contents;
}
.header-link43 {
  text-decoration: none;
}
.header-buttons2 {
  gap: var(--dl-space-space-twounits);
  display: flex;
  margin-top: var(--dl-space-space-twounits);
  align-items: center;
  flex-direction: row;
}
.header-action12 {
  text-decoration: none;
}
.header-icon-group {
  gap: var(--dl-space-space-twounits);
  display: flex;
}
.header-text3 {
  display: inline-block;
  text-decoration: none;
}
.header-text4 {
  display: inline-block;
}
.header-text5 {
  display: inline-block;
  font-style: normal;
  font-weight: 700;
  text-decoration: none;
}
.header-text6 {
  display: inline-block;
  font-style: normal;
  font-weight: 700;
  text-decoration: none;
}
.header-text7 {
  display: inline-block;
  font-style: normal;
  font-weight: 700;
  text-decoration: none;
}
.header-text8 {
  display: inline-block;
  font-style: normal;
  text-align: left;
  font-weight: 700;
  text-decoration: none;
}
.header-text9 {
  display: inline-block;
  font-style: normal;
  font-weight: 700;
  text-decoration: none;
}










@media(max-width: 991px) {
  .header-top {
    position: relative;
  }
  .header-logo {
    top: 0px;
    left: 0px;
    right: 0px;
    margin: auto;
    position: absolute;
  }
  .header-links2 {
    align-self: center;
  }
  .header-buttons2 {
    position: relative;
  }
  .header-action12 {
    top: 0px;
    left: 0px;
    margin: auto;
    position: absolute;
    align-self: flex-end;
  }
  .header-text3 {
    text-decoration: none;
  }
}
@media(max-width: 767px) {
  .header-navbar-interactive {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .header-burger-menu {
    align-items: center;
    justify-content: center;
  }
}
@media(max-width: 479px) {
  .header-navbar-interactive {
    padding: var(--dl-space-space-unit);
    padding-right: 16px;
  }
  .header-image1 {
    width: 213px;
    height: 104px;
  }
  .header-desktop-menu {
    display: none;
  }
  .header-burger-menu {
    display: flex;
  }
  .header-mobile-menu {
    padding: var(--dl-space-space-unit);
  }
}
