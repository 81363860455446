.profile-container1 {
  width: 100%;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  background-color: #212152; /* Dark background color */
}

.profile-text10, .profile-text11, .profile-text12, .profile-text13,
.profile-text14, .profile-text15, .profile-text16, .profile-text17,
.profile-text18, .profile-text19, .profile-text20, .profile-text21,
.profile-text22, .profile-text23, .profile-text24, .profile-text25,
.profile-text26 {
  display: inline-block;
  font-style: normal;
  font-weight: 700;
  text-decoration: none;
  color: #ffffff; /* White text color */
}

.profile-container2 {
  width: 100%;
  max-width: 1200px;
  padding: 20px;
  margin: 0 auto;
}

.profile-container3 {
  display: contents;
}

.card {
  background-color: #f8f9fa; /* Light grey background */
  color: #343a40; /* Dark text color */
  border: 1px solid #dee2e6; /* Subtle border */
  border-radius: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1); /* Soft shadow */
  transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
  padding: 20px; /* Added padding inside cards */
  margin-bottom: 20px; /* Spacing between cards */
}

.card:hover {
  transform: translateY(-7px); /* Slightly more lift on hover */
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.15); /* Stronger shadow on hover */
}

.card-title {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
}

.card-text {
  font-size: 1.25rem;
  font-weight: bold;
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.container {
  max-width: 1200px;
}

@media (max-width: 768px) {
  .card-title {
    font-size: 1.25rem;
  }
  .card-text {
    font-size: 1rem;
  }
}

/* Ensure the image fits within the modal while maintaining its aspect ratio */
.modal-body img {
  max-width: 100%;
  height: auto;
  display: block;
  margin: 0 auto; /* Center the image horizontally */
}

/* Add bottom margin to the button to create more space between button and the 3rd column */
.image-button-wrapper {
  margin-bottom: 40px; /* Increased bottom margin */
}

@media (max-width: 991px) {
  .profile-text14 {
    text-decoration: none;
  }
}
