.not-found-container {
  width: 100%;
  height: 400px;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.not-found-max-width {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  max-width: 600px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.not-found-text1 {
  font-size: 100px;
  text-align: center;
}
.not-found-text2 {
  text-align: center;
  text-transform: uppercase;
}
.not-found-text4 {
  display: inline-block;
}
.not-found-text5 {
  display: inline-block;
  text-align: center;
}
@media(max-width: 767px) {
  .not-found-max-width {
    align-items: center;
    padding-left: var(--dl-space-space-threeunits);
    padding-right: var(--dl-space-space-threeunits);
  }
  .not-found-text5 {
    text-align: center;
  }
}
@media(max-width: 479px) {
  .not-found-max-width {
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
  }
}
