.login-container1 {
  width: 100%;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.login-text10 {
  display: inline-block;
  font-style: normal;
  font-weight: 700;
  text-decoration: none;
}
.login-text11 {
  display: inline-block;
  font-style: normal;
  text-align: left;
  font-weight: 700;
  text-decoration: none;
}
.login-text12 {
  display: inline-block;
  font-style: normal;
  font-weight: 700;
  text-decoration: none;
}
.login-text13 {
  display: inline-block;
  font-style: normal;
  font-weight: 700;
  text-decoration: none;
}
.login-text14 {
  display: inline-block;
  text-decoration: none;
}
.login-text15 {
  display: inline-block;
  font-style: normal;
  font-weight: 700;
  text-decoration: none;
}
.login-text16 {
  display: inline-block;
}
.login-container2 {
  gap: var(--dl-space-space-unit);
  width: 100%;
  height: 800px;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.login-max-width {
  height: auto;
  display: flex;
  z-index: 100;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.login-container3 {
  gap: var(--dl-space-space-twounits);
  flex: 1;
  width: 100%;
  height: 100%;
  display: flex;
  z-index: 100;
  max-width: 500px;
  align-items: center;
  border-color: var(--dl-color-theme-accent1);
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  justify-content: center;
  background-color: var(--dl-color-theme-neutral-light);
  border-top-width: 0px;
  border-left-width: 0px;
  border-bottom-width: 0px;
  border-top-left-radius: var(--dl-radius-radius-radius4);
  border-top-right-radius: 0;
  border-bottom-left-radius: var(--dl-radius-radius-radius4);
  border-bottom-right-radius: 0;
}
.login-form1 {
  gap: var(--dl-space-space-unit);
  width: 100%;
  display: flex;
  max-width: 500px;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-end;
}
.login-form2 {
  gap: var(--dl-space-space-unit);
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
}
.login-email {
  gap: var(--dl-space-space-halfunit);
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
.login-textinput1 {
  width: 100%;
  border-color: var(--dl-color-theme-primary1);
  background-color: transparent;
}
.login-password {
  gap: var(--dl-space-space-halfunit);
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.login-textfield {
  gap: var(--dl-space-space-halfunit);
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}
.login-container4 {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.login-hide-password {
  gap: var(--dl-space-space-halfunit);
  width: auto;
  height: auto;
  display: flex;
  align-items: center;
  flex-shrink: 1;
  justify-content: center;
}
.login-icon1 {
  fill: var(--dl-color-theme-neutral-dark);
  width: 24px;
  height: 24px;
}
.login-textinput2 {
  width: 100%;
  border-color: var(--dl-color-theme-primary1);
  background-color: transparent;
}
.login-link {
  align-self: flex-end;
  text-decoration:  underline none;
}
.login-button1 {
  width: 100%;
}
.login-text21 {
  text-align: center;
}
.login-divider1 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  justify-content: center;
}
.login-divider2 {
  flex: 1;
  width: var(--dl-size-size-medium);
  height: 1px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  background-color: var(--dl-color-theme-primary1);
}
.login-divider3 {
  flex: 1;
  width: auto;
  height: 1px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  background-color: var(--dl-color-theme-primary1);
}
.login-button2 {
  gap: var(--dl-space-space-halfunit);
  width: 100%;
  display: flex;
  box-sizing: border-box;
  align-items: center;
  border-color: var(--dl-color-theme-primary1);
  border-style: solid;
  border-width: 1px;
  justify-content: center;
}
.login-icon3 {
  width: 24px;
  height: 24px;
}
.login-sign-up-image {
  top: 0px;
  left: 0px;
  right: 0px;
  width: 100%;
  bottom: 0px;
  height: 100%;
  position: absolute;
}
.login-container5 {
  top: 0px;
  flex: 0 0 auto;
  left: 0px;
  right: 0px;
  width: 100%;
  border: 2px dashed rgba(120, 120, 120, 0.4);
  bottom: 0px;
  height: 100%;
  display: flex;
  z-index: 10;
  position: absolute;
  align-items: flex-start;
  flex-direction: column;
  background-color: rgba(0,0,0,0.4);
}
.login-text24 {
  display: inline-block;
}
.login-text25 {
  display: inline-block;
}
.login-text26 {
  display: inline-block;
}
.login-text27 {
  display: inline-block;
}
.login-text28 {
  display: inline-block;
}
.login-text29 {
  display: inline-block;
}
.login-text30 {
  display: inline-block;
}
.login-text31 {
  display: inline-block;
}
.login-text32 {
  display: inline-block;
}
@media(max-width: 991px) {
  .login-text14 {
    text-decoration: none;
  }
  .login-container2 {
    gap: var(--dl-space-space-twounits);
    height: auto;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
  .login-max-width {
    flex-direction: column;
  }
  .login-container3 {
    width: 100%;
    margin-top: var(--dl-space-space-twounits);
    margin-bottom: 0px;
  }
}
@media(max-width: 767px) {
  .login-container3 {
    padding-left: var(--dl-space-space-threeunits);
    padding-right: var(--dl-space-space-threeunits);
    border-top-right-radius: var(--dl-radius-radius-radius4);
    border-bottom-right-radius: var(--dl-radius-radius-radius4);
  }
}
@media(max-width: 479px) {
  .login-container3 {
    width: 100%;
    margin-top: var(--dl-space-space-oneandhalfunits);
    margin-left: var(--dl-space-space-oneandhalfunits);
    padding-top: var(--dl-space-space-oneandhalfunits);
    margin-right: var(--dl-space-space-oneandhalfunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .login-text17 {
    text-align: center;
  }
  .login-button1 {
    width: 100%;
  }
  .login-button2 {
    width: 100%;
  }
}
