.about-us-container {
  width: 100%;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.about-us-text10 {
  display: inline-block;
  font-style: normal;
  font-weight: 700;
  text-decoration: none;
}
.about-us-text11 {
  display: inline-block;
  font-style: normal;
  text-align: left;
  font-weight: 700;
  text-decoration: none;
}
.about-us-text12 {
  display: inline-block;
  font-style: normal;
  font-weight: 700;
  text-decoration: none;
}
.about-us-text13 {
  display: inline-block;
  font-style: normal;
  font-weight: 700;
  text-decoration: none;
}
.about-us-text14 {
  display: inline-block;
  text-decoration: none;
}
.about-us-text15 {
  display: inline-block;
  font-style: normal;
  font-weight: 700;
  text-decoration: none;
}
.about-us-text16 {
  display: inline-block;
}
.about-us-text17 {
  display: inline-block;
}
.about-us-text18 {
  display: inline-block;
}
.about-us-text19 {
  display: inline-block;
}
.about-us-text20 {
  display: inline-block;
}
.about-us-text21 {
  display: inline-block;
}
.about-us-text22 {
  display: inline-block;
}
.about-us-text23 {
  display: inline-block;
}
.about-us-text24 {
  display: inline-block;
}
.about-us-text25 {
  display: inline-block;
}
.about-us-text26 {
  display: inline-block;
}
.about-us-text27 {
  display: inline-block;
}
.about-us-text28 {
  display: inline-block;
}
.about-us-text29 {
  display: inline-block;
  font-style: normal;
  font-weight: 600;
}
.about-us-text30 {
  display: inline-block;
  font-style: normal;
  font-weight: 600;
}
.about-us-text31 {
  display: inline-block;
  font-style: normal;
  font-weight: 600;
}
.about-us-text32 {
  display: inline-block;
  font-style: normal;
  font-weight: 600;
}
.about-us-text33 {
  display: inline-block;
  font-style: normal;
  font-weight: 600;
}
.about-us-text34 {
  display: inline-block;
  font-style: normal;
  font-weight: 600;
}
.about-us-text35 {
  display: inline-block;
}
.about-us-text36 {
  display: inline-block;
}
.about-us-text37 {
  display: inline-block;
  font-stretch: normal;
}
.about-us-text38 {
  display: inline-block;
}
.about-us-text39 {
  display: inline-block;
}
.about-us-text40 {
  display: inline-block;
}
.about-us-text41 {
  display: inline-block;
}
.about-us-text42 {
  display: inline-block;
}
.about-us-text43 {
  display: inline-block;
}
.about-us-text44 {
  display: inline-block;
}
.about-us-text45 {
  display: inline-block;
}
.about-us-text46 {
  display: inline-block;
}
.about-us-text47 {
  display: inline-block;
}
.about-us-text48 {
  display: inline-block;
}
.about-us-text49 {
  display: inline-block;
}
.about-us-text50 {
  display: inline-block;
}
.about-us-text51 {
  display: inline-block;
}
.about-us-text52 {
  display: inline-block;
}
.about-us-text53 {
  display: inline-block;
}
.about-us-text54 {
  display: inline-block;
}
.about-us-text55 {
  display: inline-block;
}
.about-us-text56 {
  display: inline-block;
}
.about-us-text57 {
  display: inline-block;
}
.about-us-text58 {
  display: inline-block;
}
.about-us-text59 {
  display: inline-block;
}
@media(max-width: 991px) {
  .about-us-text14 {
    text-decoration: none;
  }
}
@media(max-width: 767px) {
  .about-us-text19 {
    text-align: center;
  }
  .about-us-text20 {
    text-align: center;
  }
}
@media(max-width: 479px) {
  .about-us-text35 {
    text-align: center;
  }
}
