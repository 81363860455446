.title-subtitle-img-container1 {
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.title-subtitle-img-max-width {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.title-subtitle-img-column {
  gap: 24px;
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.title-subtitle-img-content {
  gap: 24px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.title-subtitle-img-actions {
  gap: var(--dl-space-space-unit);
  border: 2px dashed rgba(120, 120, 120, 0.4);
  display: flex;
  align-items: flex-start;
}
.title-subtitle-img-container3 {
  flex: 1;
  display: flex;
  position: relative;
  align-items: center;
  padding-top: 35%;
  justify-content: flex-start;
}
.title-subtitle-img-image1 {
  left: 0px;
  width: 100%;
  bottom: 0px;
  height: 100%;
  position: absolute;
  flex-grow: 1;
  object-fit: cover;
}
.title-subtitle-img-text1 {
  display: inline-block;
}
.title-subtitle-img-text2 {
  display: inline-block;
}
@media(max-width: 991px) {
  .title-subtitle-img-container2 {
    flex-direction: column;
  }
  .title-subtitle-img-container3 {
    width: 100%;
    padding-top: 56.25%;
  }
}
@media(max-width: 767px) {
  .title-subtitle-img-heading1 {
    text-align: center;
  }
  .title-subtitle-img-content1 {
    text-align: center;
  }
  .title-subtitle-img-actions {
    width: 100%;
    justify-content: center;
  }
  .title-subtitle-img-image1 {
    width: 100%;
  }
  .title-subtitle-img-text1 {
    text-align: center;
  }
  .title-subtitle-img-text2 {
    text-align: center;
  }
}
@media(max-width: 479px) {
  .title-subtitle-img-actions {
    flex-direction: column;
  }
}
