.home-container {
  width: 100%;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.home-text10 {
  display: inline-block;
  font-style: normal;
  font-weight: 700;
  text-decoration: none;
}
.home-text11 {
  display: inline-block;
  font-style: normal;
  text-align: left;
  font-weight: 700;
  text-decoration: none;
}
.home-text12 {
  display: inline-block;
  font-style: normal;
  font-weight: 700;
  text-decoration: none;
}
.home-text13 {
  display: inline-block;
  font-style: normal;
  font-weight: 700;
  text-decoration: none;
}
.home-text14 {
  display: inline-block;
  text-decoration: none;
}
.home-text15 {
  display: inline-block;
  font-style: normal;
  font-weight: 700;
  text-decoration: none;
}
.home-text16 {
  display: inline-block;
}
.home-text17 {
  display: inline-block;
}
.home-text18 {
  display: inline-block;
}
.home-text19 {
  display: inline-block;
}
.home-text20 {
  display: inline-block;
}
.home-text21 {
  display: inline-block;
}
.home-text22 {
  display: inline-block;
}
.home-text23 {
  display: inline-block;
}
.home-text24 {
  display: inline-block;
}
.home-text25 {
  display: inline-block;
}
.home-text26 {
  display: inline-block;
}
.home-text27 {
  display: inline-block;
}
.home-text28 {
  display: inline-block;
}
.home-text29 {
  display: inline-block;
}
.home-text30 {
  display: inline-block;
}
.home-text31 {
  display: inline-block;
}
.home-text32 {
  display: inline-block;
}
.home-text33 {
  display: inline-block;
}
.home-text36 {
  display: inline-block;
}
.home-text37 {
  display: inline-block;
}
.home-text38 {
  display: inline-block;
}
.home-text39 {
  display: inline-block;
}
.home-text40 {
  display: inline-block;
}
.home-text41 {
  display: inline-block;
}
.home-text42 {
  display: inline-block;
}
.home-text43 {
  display: inline-block;
}
.home-text44 {
  display: inline-block;
}
.home-text45 {
  display: inline-block;
}
.home-text46 {
  display: inline-block;
}
.home-text47 {
  display: inline-block;
}
.home-text48 {
  display: inline-block;
}
.home-text49 {
  display: inline-block;
}
.home-text50 {
  display: inline-block;
}
.home-text51 {
  display: inline-block;
}
@media(max-width: 991px) {
  .home-text14 {
    text-decoration: none;
  }
}
@media(max-width: 767px) {
  .home-text21 {
    text-align: center;
  }
  .home-text28 {
    text-align: center;
  }
}
