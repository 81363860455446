.services-container {
  width: 100%;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.services-text10 {
  display: inline-block;
  font-style: normal;
  font-weight: 700;
  text-decoration: none;
}
.services-text11 {
  display: inline-block;
  font-style: normal;
  text-align: left;
  font-weight: 700;
  text-decoration: none;
}
.services-text12 {
  display: inline-block;
  font-style: normal;
  font-weight: 700;
  text-decoration: none;
}
.services-text13 {
  display: inline-block;
  font-style: normal;
  font-weight: 700;
  text-decoration: none;
}
.services-text14 {
  display: inline-block;
  text-decoration: none;
}
.services-text15 {
  display: inline-block;
  font-style: normal;
  font-weight: 700;
  text-decoration: none;
}
.services-text16 {
  display: inline-block;
}
.services-text17 {
  display: inline-block;
}
.services-text18 {
  display: inline-block;
}
.services-link1 {
  text-decoration: none;
}
.services-text19 {
  display: inline-block;
}
.services-text20 {
  display: inline-block;
}
.services-link2 {
  text-decoration: none;
}
.services-text21 {
  display: inline-block;
}
.services-text22 {
  display: inline-block;
}
.services-text23 {
  display: inline-block;
}
.services-text24 {
  display: inline-block;
}
.services-text25 {
  display: inline-block;
}
.services-text26 {
  display: inline-block;
}
.services-text27 {
  display: inline-block;
}
.services-text28 {
  display: inline-block;
}
.services-text29 {
  display: inline-block;
}
@media(max-width: 991px) {
  .services-text14 {
    text-decoration: none;
  }
}
