.title-subtitle-max-width {
  display: flex;
  align-self: center;
  align-items: center;
  justify-content: center;
}
.title-subtitle-content {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  max-width: 1000px;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
.title-subtitle-text3 {
  display: inline-block;
  text-align: center;
}
.title-subtitle-text4 {
  display: inline-block;
  text-align: center;
}




